<template>
  <div>
    <template v-if="showDataViewer">
      <div class="pt-1">
        <div
          :style="{ height: reportHeight + 'px' }"
          v-resize="setHeight"
          class="mb-3"
        >
          <HummingbirdReportViewer
            :show_divider="false"
            show_search
            hide_back_link
            report_type="promotion_library"
            :grid-conf="gridConf"
            :column-conf="columnConf"
            action-button-label="Create New Promotion"
            :default_search_filters="search_filter"
            @action-btn-click="editPromo"
            @gridReady="(api) => (gridApi = api)"
            right_click
            class="promotion-library-bi"
          >
            <template v-slot:globalFilter>
              <div class="d-flex align-center">
                <hb-autocomplete
                  rounded
                  v-model="filter"
                  :items="filters"
                  item-value="id"
                  item-text="name"
                  :clearable="false"
                  width="370px"
                  @change="updatePromoLibrary"
                />
              </div>
            </template>
          </HummingbirdReportViewer>
        </div>
        <promo-edit-form
          v-model="viewPromo"
          ref="promoForm"
          :promotion="promoData"
          @close="updateRowData($event)"
        />
        <delete-promo
          ref="deletePromoPop"
          @deleted="updateRows($event)"
          @close="updateRowData($event)"
        />
        <duplicate-promo
          ref="duplicatePromoPop"
          @duplicated="updateRows($event)"
          @close="updateRowData($event)"
        />
        <hb-modal
          v-model="permissionError"
          size="large"
          title="Unable to Create New Promotion"
          confirmation
          @close="permissionError = false"
          :footer-off="true"
        >
          <template v-slot:content>
            <div class="pa-4">
              <div class="pb-2">
                The signed in account does not have permission to
                <strong>Create New Promotion</strong>, contact your administrator to
                enable permission.
              </div>
            </div>
          </template>
        </hb-modal>
      </div>
    </template>
    <hb-empty-state v-else class="mt-4" :message="getEmptyTextMsg" />
  </div>
</template>
<script>
import HummingbirdReportViewer from "../../assets/HummingbirdReportViewer.vue";
import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import ClickCellRenderer from "@/components/BI/ClickCellRenderer.vue";
import PromoEditForm from "./PromoEditForm.vue";
import PromotionTooltip from "@/components/revenue_management/promotion_management/PromotionTooltip.vue";
import PromotionsLibraryActions from "./PromotionsLibraryActions.vue";
import PromotionsEffectRenderer from "./PromotionsEffectRenderer.vue";
import DeletePromo from "./DeletePromo.vue";
import DuplicatePromo from "./DuplicatePromo.vue";

import { EventBus } from "../../../EventBus.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PromotionAndDiscount",
  components: {
    HummingbirdReportViewer,
    MultiLineRenderer,
    ClickCellRenderer,
    PromoEditForm,
    PromotionTooltip,
    PromotionsEffectRenderer,
    DeletePromo,
    DuplicatePromo,
  },
  props: {
    permissions: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      gridApi: null,
      permissionError: false,
      reportHeight: 0,
      viewPromo: false,
      promoData: {},
      filter: null,
      filters: [
        { id: null, name: "All Promotions & Discounts" },
        { id: "promotion", name: "All Promotions" },
        { id: "discount", name: "All Discounts" },
      ],
    };
  },
  async mounted() {
    // this.setDynamicRuntimeProperties(null)
    let events = {
      editPromo: this.editPromo,
      deletePromo: this.deletePromo,
      duplicatePromo: this.duplicatePromo,
      setAsDefaultPromo: this.setAsDefaultPromo,
      disableEnablePromo: this.disableEnablePromo,
      triggerTableRefresh: this.updateRows,
    };
    EventBus.$on("promoLibraryEvents", ({ event, data }) => {
      if (events[event]) events[event](data);
    });
    this.fetchProducts();
  },
  computed: {
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      getDefaultSearchFilter: "reportStore/getDefaultSearchFilter",
      propertyPromotionSettings: "revManStore/getPropertyPromotionSettings",
    }),
    computedNationalPermission() {
      return this.hasPermission("national_account_enabled")
    },
    hasManagePromotionPermission() {
      return this.permissions?.manage_promotions ?? false;
    },
    search_filter() {
      return [
        {
          id: "promotion_filter",
          value: this.filter,
        },
      ];
    },
    hasPropertySelector() {
      return this.properties.length > 1;
    },
    showDataViewer() {
      return this.selectedProperty
        ? this.propertyPromotionSettings.active
        : true;
    },
    getEmptyTextMsg() {
      return "Promotion Management is not enabled for this Property";
    },
    gridConf() {
      return {
        props: {
          BIHelpers: this.hasManagePromotionPermission
            ? {
                kebab: {
                  enabled: true,
                  icon: "mdi-dots-vertical",
                  component: {
                    definition: PromotionsLibraryActions,
                    props: {
                      promoId: this.promoData?.id,
                      permissions: this.permissions,
                    },
                  },
                  action: "click",
                },
              }
            : {},
          overlayNoRowsTemplate:
            '<span style="font-size: 20px;padding: 10px;">No Promotions/Discounts</span>',
          getRowId: (params) => params?.data?.promotion_id,
        },
      };
    },
    columnConf() {
      return {
        promotion_name: {
          headerName: "Name & Description",
          cellRenderer: "MultiLineRenderer",
          tooltipComponent: "PromotionTooltip",
          tooltipComponentParams: {
            showToolTip: (val) => !!(val && val?.length),
            showPromoDetails: true,
            rendererParams: (val) => {
              return {
                promotion_type: val.promotion_type,
                name: val.promotion_name,
                description: val.promotion_description,
              };
            },
          },
          cellRendererParams: {
            show_promo_name: true,
            fields: [
              {
                column: "promotion_name",
              },
              {
                column: "promotion_description",
                seperator: "dashed",
                color: "#8E8E8F",
              },
            ],
          },
          pinned: "left",
        },
        promotion_type: {
          headerName: "Type",
          cellRenderer: (params) => {
            if (params.valueFormatted) {
              return `<span style="text-transform: capitalize">${params.valueFormatted}</span>`;
            }
          },
        },
        promotion_amount: {
          headerName: "Amount",
          cellRenderer: (params) => {
            if (params.valueFormatted) {
              let amount = "";
              if(params.valueFormatted.type == 'dollar' || params.valueFormatted.type == 'fixed'){
                amount += "$"
              }
              amount += `${params.valueFormatted.value}`;
              if(params.valueFormatted.type == 'percent'){
                amount += "%"
              }
              if(params.valueFormatted.type == 'fixed'){
                amount += " Fixed"
              }
              if(params.valueFormatted.type == 'dollar' || params.valueFormatted.type == 'percent'){
                amount += " Off"
              }
              return `<span>${amount}</span>`;
            }
          },
        },
        promotion_start: {
          headerName: "Start/Duration",
          tooltipComponent: "PromotionTooltip",
          tooltipComponentParams: {
            showToolTip: (val) => !!(val >= 0),
            rendererParams: (val) => {
              return {
                promotion_type: val.promotion_type,
              };
            },
          },
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "promotion_start",
                formatter: (val) => (val !== "" ? `Month ${val + 1}` : ""),
              },
              {
                column: "promotion_duration",
                formatter: (val) => {
                  let formattedText =
                    val && val > 1 ? `for ${val} Months` : `for ${val} Month`;
                  return val ? formattedText : "";
                },
                color: "#8E8E8F",
              },
            ],
          },
        },
        promotion_conditions: {
          headerName: "Conditions",
          tooltipComponent: "PromotionTooltip",
          tooltipComponentParams: {
            showToolTip: (val) => !!(val && val?.length),
            rendererParams: (val) => {
              return {
                promotion_type: val.promotion_type,
              };
            },
          },
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "promotion_conditions",
                customClass: (val) =>
                  val && val.length > 1 ? "dashed-underline" : "",
                formatter: (val) => {
                  if (val.length) {
                    const conditions = {
                      new_tenants: "New Tenants Only",
                      existing_tenants: "Existing Tenants Only",
                      web_only: "Web Only",
                      require_autopay: "Require Autopay",
                      require_coverage: "Require Protection Plan Enrollment",
                      require_id_verification: "Require ID Verification",
                      remove_on_delinquency: "Remove on Delinquency",
                    };
                    let count = val.length;
                    let value =
                      count > 1
                        ? `(${count}) ${conditions[val[0]]}`
                        : `${conditions[val[0]]}`;
                    return `${value}`;
                  }
                },
              },
            ],
          },
        },
        // promotion_notes: {
        //   filter: false,
        //   cellRenderer: "ClickCellRenderer",
        //   cellRendererParams: {
        //     type: "link",
        //     formatter: (val) => (val ? "View Notes" : ""),
        //     // event: {
        //     //   name: "",
        //     //   method: "viewNotes",
        //     // },
        //   },
        // },
        promotion_properties: {
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "promotion_properties",
                customClass: (val) =>
                  val && val > 0 ? "dashed-underline" : "",
                formatter: (val) => `${val}`,
              },
            ],
          },
        },
        promotion_is_national_account:{
          hide: !this.computedNationalPermission
        }
      };
    },
  },
  watch: {},
  beforeDestroy() {
    this.setDynamicRuntimeProperties(null);
    //EventBus.$off("tenantRentManagementEvent");
  },
  methods: {
    ...mapActions({
      fetchPromotions: "revManStore/fetchActivePromotions",
      fetchProducts: "revManStore/fetchProducts",
    }),
    triggerTableRefresh(data = {}, columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.gridApi?.refreshServerSide({ route: columns, purge: true });
    },

    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },

    updateRowData(pKey, data = {}) {
      let node = null;
      if (this.gridApi) node = this.gridApi.getRowNode(pKey);
      if (node) node.setData({ ...node.data, ...data });
    },

    updatePromoLibrary() {
      let defaultSearchFilter = JSON.parse(
        JSON.stringify(this.getDefaultSearchFilter)
      );
      defaultSearchFilter.filter.forEach((item) => {
        if (item.id == "promotion_filter") item.value = this.filter;
      });
      this.$store.commit(
        "reportStore/setDefaultSearchFilterData",
        defaultSearchFilter
      );
      this.triggerTableRefresh();
    },

    setHeight() {
      let header = 40;
      let stepper = 40;
      let tabs = 40;
      let propertySelector = this.hasPropertySelector ? 60 : -20;
      let footer = 40;
      let heading = 40;
      let padding = 52;
      this.reportHeight =
        window.innerHeight -
        header -
        stepper -
        tabs -
        heading -
        footer -
        padding -
        propertySelector;
    },

    deletePromo(data) {
      this.$refs.deletePromoPop?.show({
        rowData: data,
      });
    },
    duplicatePromo(data) {
      this.$refs.duplicatePromoPop?.show({
        rowData: data,
      });
    },
    async updateRows(data) {
      await this.fetchPromotions();
      this.triggerTableRefresh();
    },
    editPromo(data = null) {
      if (this.hasManagePromotionPermission) {
        this.viewPromo = true;
        this.$refs.promoForm?.show({
          rowData: data,
        });
      } else this.permissionError = true;
    },
  },
};
</script>
